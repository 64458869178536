import React, { useEffect, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomAds } from "../redux/slices/customAdsSlice";
import logo from "../assets/cxotv-header-logo.jpg";
import "../App.css";
import Section from "../components/common/Section";
import HomeSlider from "../components/common/HomeSlider";
import { Helmet } from "react-helmet-async";
const Header = lazy(() => import("../components/common/Header"));
const Navbar = lazy(() => import("../components/common/Navbar"));
const NewsSection = lazy(() => import("../components/common/NewsSection"));
const TwoCategoryData = lazy(() =>
  import("../components/common/TwoCategoryData")
);
const Ad = lazy(() => import("../components/common/Ad"));
const Footer = lazy(() => import("../components/common/Footer"));

const Home = () => {
  const dispatch = useDispatch();
  const { isAdLoading, isError } = useSelector((state) => state.customAds);

  useEffect(() => {
    dispatch(fetchCustomAds());
  }, [dispatch]);

  if (isAdLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Suspense
          fallback={
            <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
              <div className="spinner"></div>
            </div>
          }
        >
          <Header logo={logo} />
        </Suspense>
        <div className="flex flex-col items-center justify-center py-20 gap-8">
          <p className="lg:text-[40px] text-red-600 font-fira capitalize">
            Server Error
          </p>
          <div className="text-red-600 font-roboto text-4xl font-bold">
            Oops! Something Went Wrong
          </div>
          <p className="text-gray-700 text-lg text-center">
            Sorry, there seems to be an error on the server side. Please refresh
            the page or try again later.
          </p>
        </div>

        <Suspense
          fallback={
            <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
              <div className="spinner"></div>
            </div>
          }
        >
          <Footer />
        </Suspense>
      </div>
    );
  }

  const categoryData = [
    { name: "Tech Thursday", title: "TECH THURSDAY" },
    { name: "BFSI", title: "CXO BFSI" },
    { name: "Health Technology", title: "CXO HEALTH" },
    { name: "Education Technology", title: "CXO EDUCATION" },
    { name: "Marketing Mondays", title: "MARKETING" },
    { name: "Technology", title: "TECH NEWS" },
  ];

  const categoriesWithHeadings1 = [
    { name: "Technology", heading: "Technology" },
    { name: "Interviews", heading: "Interviews" },
  ];

  const categoriesWithHeadings2 = [
    { name: "what's popular", heading: "WHAT’S POPULAR" },
    { name: "editor's choice", heading: "EDITOR'S CHOICE" },
  ];

  const names = [
    "Tech Priorities",
    "Tech Thursday",
    "Interviews",
    "CFO Playbook",
    "Developers",
    "Cyberwatch",
    "CXO Talk",
    "Marketing Mondays",
    "Talks with Kalpana",
    "CEO Talk",
  ];

  const sliderLeft = ["CXO Talk", "Marketing Mondays", "CEO Talk"];

  return (
    <>
      <Helmet>
        <title>Latest Technology News - CXOTV Today & Tech Updates</title>
        <meta
          name="description"
          content="Stay updated with the latest technology news, CXO insights, and expert opinions on CXO TV. Get the latest tech updates and exclusive interviews."
        />
        <meta
          name="keywords"
          content="technology news, CXO TV, tech updates, latest tech trends, digital transformation"
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Latest Technology News - CXOTV Today & Tech Updates"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://cxotv.techplusmedia.com/" />

        {/* Schema.org Markup for Website */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://cxotv.techplusmedia.com/",
        "name": "CXOTV - Latest Technology News and Updates",
        "description": "Stay updated with the latest technology news, CXO insights, and expert opinions on CXO TV. Get the latest tech updates and exclusive interviews.",
        "publisher": {
          "@type": "Organization",
          "name": "CXOTV",
          "logo": {
            "@type": "ImageObject",
            "url": "${logo}" 
          }
        }
      }
    `}
        </script>
      </Helmet>

      <h1 className="sr-only">
        Latest Technology News - CXOTV Today & Tech Updates
      </h1>
      <h2 className="sr-only">Latest Technology News & Trends</h2>

      <div className="sr-only">
        <a href="https://cxotv.techplusmedia.com/about-us" aria-hidden="true">
          About Us
        </a>
        <a
          href="https://cxotv.techplusmedia.com/terms-and-conditions"
          aria-hidden="true"
        >
          Terms & Conditions
        </a>
        <a
          href="https://cxotv.techplusmedia.com/advertising"
          aria-hidden="true"
        >
          Advertising
        </a>
        <a
          href="https://cxotv.techplusmedia.com/privacy-policy"
          aria-hidden="true"
        >
          Privacy Policy
        </a>
      </div>

      <Suspense
        fallback={
          <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
            <div className="spinner"></div>
          </div>
        }
      >
        <div className="flex flex-col gap-3 mx-auto w-full">
          <Header logo={logo} />
          <Navbar />

          <div className="mx-auto lg:w-[50%] w-[90%]">
            <Ad name="tech-summit-2023" />
          </div>

          <div className="py-2">
            <HomeSlider category="Trending News" names={sliderLeft} />
          </div>
          <div>
            <Section names={names} />
          </div>

          <div className="w-full py-10 px-4 mx-auto items-center bg-[#F3F2F6]">
            <TwoCategoryData
              active={true}
              categoriesWithHeadings={categoriesWithHeadings1}
            />
          </div>

          <NewsSection categoryData={categoryData} />

          <div className="mx-auto lg:w-[50%] w-[90%]">
            <Ad name="Data-cloud" />
          </div>

          <div className="w-full py-10 px-5 mx-auto items-center bg-[#F3F2F6]">
            <TwoCategoryData categoriesWithHeadings={categoriesWithHeadings2} />
          </div>

          <Footer />

          <div className="mx-auto lg:w-[50%] w-full">
            <Ad name="Data-cloud" />
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Home;
